import React from "react";

function NotFound({ statusCode }) {
    return (
        <p>
            {statusCode === 404
                ? `The page can not be found`
                : 'An error occurred on client'}
        </p>
    )
}

export default NotFound
